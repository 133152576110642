import styled from "styled-components";
import composeCss, { chooseHorizontalAlignValue } from "../../composeCss";
import { convertColorIdToHex } from "../../settingsPatterns.methods";
import { IBoxImageSettings } from "./StyledImage.types";

export const generateBoxImageStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxImageSettings["$settings"];
  theme: IBoxImageSettings["theme"];
}) => {
  const cssStyles = `
    position: relative;
    display: flex;
    padding: ${composeCss.padding($settings.padding)};
    margin: ${composeCss.margin($settings.margin)};
    justify-content: ${chooseHorizontalAlignValue($settings.contentAlignment)};

    .svg {
      background-color: ${convertColorIdToHex(
        $settings.imageColor.color,
        theme.colorPalette
      )};
      
      width: 100%;
      height: ${$settings.imageSize.height}px;
      mask: url(${$settings.imageUrl}) no-repeat ${
    $settings.contentAlignment
  } / contain;
      -webkit-mask: url(${$settings.imageUrl}) no-repeat ${
    $settings.contentAlignment
  } / contain;
      object-fit: ${$settings.imagePlacement ? "contain" : "cover"};
      opacity: ${$settings.imageOpacity / 100};
      ${composeCss.hoverEffect($settings.imageHover)}

      &:hover{
        background-color: ${convertColorIdToHex(
          $settings.imageColor.hoverColor,
          theme.colorPalette
        )};
      }
    }

    .imageContainer {
      overflow: hidden;
      ${composeCss.hoverEffect($settings.imageHover)}

      img {
        height: ${$settings.imageSize.height}px !important;
      }

      width: 100%;
    }
  `;

  return cssStyles;
};

export const StyledBoxImage = styled.div<IBoxImageSettings>((props) =>
  generateBoxImageStyles(props)
);

export const StyledHrefWrapper = styled.div`
  .box-image-href {
    height: 100%;
    position: relative;
    width: 100%;
    flex-shrink: 1;
  }
`;
