import React, { memo } from "react";
import { isEqual } from "lodash";
import withHydrationOnDemand from "react-hydration-on-demand";
import styled, { useTheme } from "styled-components";
import {
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { getImageNameFromUrl, isPortalSide } from "../../helpers/helpers";
import { StyledBoxImage } from "../../settingsPatterns/boxes/BoxImage/StyledImage";
import { useLinkContentActions } from "../../shared/hooks/useLinkContentAction";
import {
  BoxImageContentSettingsScheme,
  BoxImageDisplaySettingsScheme,
  getBoxImageContentScheme,
} from "./BoxImage.scheme";
import { IBoxImageProps } from "./BoxImage.types";
import { isColorTransparent } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";

const StyledHrefWrapper = styled.a`
  height: 100%;
  position: relative;
  width: 100%;
  flex-shrink: 1;
  cursor: pointer;
`;

const BoxImage: React.FC<IBoxImageProps> = (props) => {
  const { getHref, linkContentActions, getTarget, anyActionIsActive } =
    useLinkContentActions(props.path);
  const [imgWrapper, imageWidth] = useImageSize();

  const theme = useTheme() as IThemeState;
  const backgroundUrl = theme.advanceSettings.settings.backgroundUrl;

  const content = getBoxImageContentScheme(backgroundUrl).parse(props.content);

  const contentSettings = BoxImageContentSettingsScheme.parse(
    props.contentSettings
  );

  const displaySettings = BoxImageDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const settings = { ...content, ...contentSettings, ...displaySettings };

  const imageLinkHref = getHref(content.imageLink);

  const isSvg = content.imageUrl.endsWith(".svg");

  if (content.imageUrl === "" && !isPortalSide()) {
    return <div>No content to display</div>;
  }

  return (
    <StyledBoxImage
      ref={imgWrapper}
      $settings={settings}
      data-testid="BoxImage"
      className="image-container image-custom-style"
    >
      {
        <ConditionalWrapper
          condition={anyActionIsActive(content.imageLink)}
          wrapper={(children) => (
            <StyledHrefWrapper
              {...(imageLinkHref !== undefined && { href: imageLinkHref })}
              target={getTarget(content.imageLink)}
              onClick={() => linkContentActions(content.imageLink)}
            >
              {children}
            </StyledHrefWrapper>
          )}
        >
          {isSvg &&
          !isColorTransparent(
            contentSettings.imageColor.color,
            theme.colorPalette
          ) ? (
            <div
              role="icon"
              aria-label={
                content.seoDescription
                  ? content.seoDescription
                  : getImageNameFromUrl(content.imageUrl)
              }
              className="svg image-container__svg"
            />
          ) : content.imageUrl !== "" && settings.imageSize.height !== 0 ? (
            <div className="imageContainer image-container__image">
              <ImageWrapper
                imageUrl={content.imageUrl}
                seoDescription={content.seoDescription}
                imageFit={!settings.imagePlacement}
                imageAlignment={{
                  horizontal: settings.contentAlignment,
                  vertical: AlignmentVerticalOption.CENTER,
                }}
                opacity={settings.imageOpacity}
                width={imageWidth}
                height={settings.imageSize.height ?? 0}
              />
            </div>
          ) : (
            <div></div>
          )}
        </ConditionalWrapper>
      }
    </StyledBoxImage>
  );
};
const MemoizedBoxImage = memo(BoxImage, isEqual);

export default withHydrationOnDemand({ on: ["visible"] })(MemoizedBoxImage);
